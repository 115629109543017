import * as React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import './../styles/reset.css'
import './../styles/common.css'
import './../styles/style.css'

export const Layout = ({ isHomepage, children, navigation }) => (
  <div style={{ minHeight: '100vh' }}>
    <Header isHomepage={isHomepage} navigation={navigation} />
    <div style={{ minHeight: '95vh' }}>
      {children}
    </div>
    <Footer />
  </div>
)
