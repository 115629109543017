import * as React from 'react'
import { Container } from 'react-bootstrap'

import prismicLogo from '../images/logo-prismic.svg'

export const Footer = () => (
	<div >
		<Container fluid style={{ background: 'white' }}>
			<Container style={{paddingTop:'3em', paddingBottom: '3em'}}>
				&copy; 2022 Excerpts, Lighthouse Media
			</Container>
		</Container>
	</div>
)
