import * as React from 'react'
import { Link } from 'gatsby'
import { Container, Navbar, Nav } from 'react-bootstrap'

export const Header = ({ isHomepage }) => {
	const homepageClass = isHomepage ? 'homepage-header' : ''
	return (
		<Container fluid style={{ background: 'white' }}>
			<Container>
				<Navbar>
					<Navbar.Brand href="/">
						<h2>excerpts</h2>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						{/* <Nav className="ms-auto">
						<Nav.Link href="#home">Home</Nav.Link>
						<Nav.Link href="#link">Link</Nav.Link>

					</Nav> */}
					</Navbar.Collapse>
				</Navbar>
			</Container>
		</Container>
	)
}
